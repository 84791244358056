// ** Typography **
$clr-drk-grey: #343434;
$clr-mid-grey: #616161;
$clr-light-grey: #DDDDDD;
$clr-accent: #FF1145;
$clr-white: #FFF;
$clr-off-white: #DDD;

// ** Project Colour Palette **
$clr-drk-blue: #4A7E92;
$clr-mid-blue: #5DB6D7;
$clr-light-blue: #C4F9FF;

$clr-hamburger: #D8D8D8;
$clr-mm-links: #5C5C5C;
$clr-mm-links-active: #FFF;
$clr-desktop-links: #FFF;
$clr-desktop-link-active: #2D2D2D;
$clr-link-accent-background: #FF1145;
$clr-link-accent-background-border: #FF698A;
$clr-our-values-background: #F7F7F7;
$clr-card-background: #F3F3F3;

// Forms
$clr-form-input-background: #F9F9F9;
$clr-form-input-text: rgb(157, 157, 157);