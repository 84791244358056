.footer {
    border-top: solid #5DB6D7 0.7rem;
    background: #343434;
    margin-top: 7.2rem;
    padding-bottom: 3rem;
    background-image: url("../../images/global/footer_bg_logo_mobile.png");
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: 412px) {
        background-image: url("../../images/global/footer_bg_logo_desktop.png");
    }

    @media screen and (min-width: 580px) {
        background-size: 1000px 950px;
    }

    @media screen and (min-width: 1000px) {
        background-size: 1300px 950px;
        background-position-y: -200px;
    }

    .container {
        .content-wrapper {
            text-align: center;

            .link-wrapper {
                text-align: right;

                .back-to-top {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 400;
                    color: #616161;
                    padding: 2rem 0 0 2rem;
                    background: none;
                    border: none;
                    outline: none;

                    @media screen and (min-width: 768px) {
                        padding: 2rem 2rem 0 2rem;
                    }
                }
            }

            .top-section {
                @media screen and (min-width: 768px) {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    // Padding centers copyright text and logo
                    padding-left: 3rem;
                }

                @media screen and (min-width: 1024px) {
                    padding-left: 9rem;
                }

                .logo-wrapper {
                    order: 1;

                    @media screen and (min-width: 768px) {
                        flex: 0 0 30%;
                    }

                    .logo {
                        width: 8.5rem;
                        margin: 6.2rem auto 0 auto;
                    }
                }

                .azwun-btn {
                    margin-top: 5rem;
                    order: 2;
                }

                .socials {
                    order: 0;

                    .content-wrapper {
                        display: flex;
                        justify-content: space-around;
                        width: 120px;
                        margin: 7rem auto 0 auto;

                        @media screen and (min-width: 768px) {
                            width: auto;
                        }

                        .icn {
                            a {
                                display: inline-block;
                                color: #FF1145;
                                padding: 1rem 0.5rem 0 0.5rem;
                                margin: 0 0.5rem;

                                i {
                                    font-size: 24px;
                                }
                            }
                        }
                    }
                }
            }

            .bottom-section {
                .copyright {
                    margin-top: 2rem;

                    .content-wrapper {
                        p {
                            font-size: 14px;
                            color: #DDD;
                            line-height: 1.5;
                            margin-bottom: 0;

                            span {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}