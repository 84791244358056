@import './typography';

html {
    font-size: 62.5%;
}

body {
    line-height: 2;
    overflow-x: hidden;
    background: #FFF;
    font-family: 'Exo 2',
        sans-serif;

    @media screen and (max-width: 475px) {

        .container {
            max-width: 100%;
            overflow-x: hidden;
        }
    }

    img {
        display: block;
        width: 100%;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        color: #616161;
        line-height: 2;
    }

    .under-nav {
        margin-top: 6.8rem;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none !important;
        }
    }
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;

    .site-content {
        flex: 1;
    }
}

a {
    font-weight: 700;

}

h1 {
    font-weight: 700;
    font-size: 40px;
    text-transform: capitalize;
    color: #343434;
    letter-spacing: 2px;

    span {
        color: #FF1145;
    }

    @media screen and (min-width: 1024px) {
        font-size: 50px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 60px;
    }
}

h2 {
    font-size: 30px;
    font-weight: 700;
    color: #616161;
    letter-spacing: 2px;

    @media screen and (min-width: 1200px) {
        font-size: 32px;
    }
}

h3 {
    font-size: 20px;
    font-weight: 500;
    color: #616161;

    @media screen and (min-width: 1200px) {
        font-size: 32px;
    }
}