@mixin transition-3s {
    transition: all 0.3s ease-in-out;
}

@mixin transition-4s {
    transition: all 0.4s ease-in-out;
}

@mixin transition-5s {
    transition: all 0.5s ease-in-out;
}